import { createRouter, createWebHistory } from "vue-router";

const routes = [
	{
		path: "/",
		name: "home",
		component: () => import("../views/ViewHome.vue"),
		meta: {
			title: "Un conseil adapté pour l’aboutissement de vos projets",
			description:
				"L'Office des Deux Clés vous accompagne à chaque projet de vie et au quotidien, nous privilégions l’écoute du client ainsi que la meilleure réactivité possible.",
		},
	},
	{
		path: "/a-propos/equipe",
		name: "team",
		component: () => import("../views/ViewTeam.vue"),
		meta: {
			title: "L'équipe",
			description: "Liste détaillée des membres de l'Office des Deux Clés.",
		},
	},
	{
		path: "/a-propos/office",
		name: "office",
		component: () => import("../views/ViewOffice.vue"),
		meta: {
			title: "L'office",
			description:
				"L’office se situe au 122 route d’Auxerre à Saint-André-les-Vergers. Ouvert du lundi au vendredi de 9h à 12h et de 14h à 18h et le samedi matin sur rendez-vous.",
		},
	},
	{
		path: "/a-propos/actualites",
		name: "news",
		component: () => import("../views/ViewNews.vue"),
		meta: {
			title: "Actualités",
			description: "Consulter les dernières actualités du notariat.",
		},
	},
	{
		path: "/expertises",
		name: "expertises",
		component: () => import("../views/ViewExpertises.vue"),
		meta: {
			title: "Expertises",
			description:
				"Découvrir les expertises de l'office : droit de la famille, droit des affaires,  droit immobilier. Prendre rendez-vous en ligne.",
		},
	},
	{
		path: "/informations-&-outils",
		name: "infos",
		component: () => import("../views/ViewInfos.vue"),
		meta: {
			title: "Informations & outils",
			description:
				"Accès aux espaces clients particuliers et professionnels. Liste de liens et documents utiles.",
		},
	},
	{
		path: "/informations-&-outils/tarifs",
		name: "prices",
		component: () => import("../views/ViewPrices.vue"),
		meta: {
			title: "Tarifs",
			description: "Tarifs de l'Office des Deux Clés.",
		},
	},
	{
		path: "/informations-&-outils/missions-du-notaire",
		name: "missions",
		component: () => import("../views/ViewMissions.vue"),
		meta: {
			title: "Missions du notaire",
			description:
				"Le notaire est un juriste investi d’une mission d’autorité publique qui prépare des contrats sous la forme authentique pour le compte de ses clients.",
		},
	},
	{
		path: "/contact",
		name: "contact",
		component: () => import("../views/ViewContact.vue"),
		meta: {
			title: "Contact",
			description:
				"Contacter l'Office des Deux Clés via mail, téléphone ou réseaux sociaux.",
		},
	},
	{
		path: "/mentions-legales",
		name: "legal",
		component: () => import("../views/ViewLegal.vue"),
		meta: {
			title: "Mentions légales",
			description: "Mentions légales de l'Office des Deux Clés.",
		},
	},
	{
		path: "/:pathMatch(.*)*",
		name: "not-found",
		component: () => import("../views/ViewNotFound.vue"),
		meta: {
			title: "Erreur 404",
			description: "La page recherchée n'existe pas.",
		},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { top: 0 };
		}
	},
});

router.beforeEach((to, from, next) => {
	const title = `${
		to.meta.title ? `${to.meta.title} | ` : ""
	}Office des Deux Clés`;
	document.title = title;
	document.querySelector("meta[property='og:title']").content = title;

	document.querySelector("meta[name='description']").content =
		to.meta.description;
	document.querySelector("meta[property='og:description']").content =
		to.meta.description;

	next();
});

export default router;
