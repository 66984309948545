<template>
	<footer class="footer">
		<nav class="footer-nav">
			<FooterList>
				<li>
					<router-link class="footer-link" :to="{ name: 'home' }"
						>Accueil</router-link
					>
				</li>
				<li>
					<router-link class="footer-link" :to="{ name: 'legal' }"
						>Mentions légales</router-link
					>
				</li>
				<li>
					<NavLinkExternal
						class="footer-link"
						link="https://www.espacenotarial.com/"
						text="Espace notarial"
					/>
				</li>
				<li>
					<NavLinkExternal
						class="footer-link"
						link="https://www.csn.notaires.fr/fr"
						text="Conseil supérieur du notariat"
					/></li
			></FooterList>
			<div class="footer-nav-ctn">
				<FooterList listTitle="A propos"
					><li>
						<router-link class="footer-link" :to="{ name: 'team' }"
							>L'équipe</router-link
						>
					</li>
					<li>
						<router-link class="footer-link" :to="{ name: 'office' }"
							>L'office</router-link
						>
					</li>
					<li>
						<router-link class="footer-link" :to="{ name: 'news' }"
							>Actualités</router-link
						>
					</li></FooterList
				>
				<FooterList listTitle="Expertises"
					><li>
						<router-link class="footer-link" :to="{ name: 'expertises' }"
							>Expertises</router-link
						>
					</li></FooterList
				>
				<FooterList listTitle="Informations & outils"
					><li>
						<router-link class="footer-link" :to="{ name: 'infos' }"
							>Informations & outils</router-link
						>
					</li>
					<li>
						<router-link class="footer-link" :to="{ name: 'prices' }"
							>Tarifs</router-link
						>
					</li>
					<li>
						<router-link class="footer-link" :to="{ name: 'missions' }"
							>Missions du notaire</router-link
						>
					</li>
					<li>
						<NavLinkExternal
							class="footer-link"
							text="Prendre rendez-vous en ligne"
							:link="externalNavLinks.appointment"
						/>
					</li>
					<li>
						<NavLinkExternal
							class="footer-link"
							text="Accès espace client particuliers"
							:link="externalNavLinks.clientParticular"
						/>
					</li>
					<li>
						<NavLinkExternal
							class="footer-link"
							text="Accès espace client professionnels"
							:link="externalNavLinks.clientProfessional"
						/></li
				></FooterList>
				<FooterList listTitle="Contact"
					><li>
						<ContactTel class="footer-link" />
					</li>
					<li>
						<ContactMail class="footer-link" />
					</li>
					<li>
						<ul class="footer-list-networks">
							<li
								v-for="network in contacts.networks"
								:key="contacts.networks.indexOf(network)"
							>
								<ContactNetwork :network="network" />
							</li>
						</ul></li
				></FooterList>
			</div>
		</nav>
		<div class="footer-ctn-bottom">
			<p class="footer-text">
				@2023 Office des Deux Clés<br />
				Design & code par <strong>Maxime Kerfourn</strong>
			</p>
			<div class="footer-logos">
				<img
					class="footer-logo-office"
					src="@/assets/logo-office-v1.png"
					alt="Logo de l'Office des Deux Clés"
					width="200"
					height="118"
				/>
				<LogoNotary />
			</div>
		</div>
	</footer>
</template>

<script>
import FooterList from "./FooterList.vue";
import ContactTel from "./ContactTel.vue";
import ContactMail from "./ContactMail.vue";
import ContactNetwork from "./ContactNetwork.vue";
import NavLinkExternal from "./NavLinkExternal.vue";
import LogoNotary from "./LogoNotary.vue";
import { mapGetters } from "vuex";

export default {
	name: "AppFooter",

	components: {
		FooterList,
		ContactTel,
		ContactMail,
		ContactNetwork,
		NavLinkExternal,
		LogoNotary,
	},

	computed: {
		...mapGetters(["contacts", "externalNavLinks"]),
	},
};
</script>

<style>
.footer {
	background-color: var(--color-secondary-darker);

	padding: 7.2rem var(--margin-sides) 4rem;
	font-size: 1.6rem;
	color: var(--color-white);
}

.footer-nav {
	display: flex;
	gap: max(6%, 8.6rem);
	align-items: flex-start;
}

.footer-nav-ctn {
	display: flex;
	gap: 5rem max(6%, 8.6rem);
	flex-wrap: wrap;
	align-items: flex-start;
}

.footer-link {
	text-decoration: none;
	color: var(--color-white);
	opacity: 0.5;
	white-space: nowrap;
}

@media (any-hover: hover) {
	.footer-link:hover {
		text-decoration: underline;
		color: var(--color-primary);
		opacity: 1;
	}
}

.footer-list-networks {
	list-style-type: none;
	display: flex;
	gap: 2.25rem;
}

.footer-list-networks .contact-network {
	color: var(--color-white);
	opacity: 0.5;
}

@media (any-hover: hover) {
	.footer-list-networks .contact-network:hover {
		color: var(--color-primary);
		opacity: 1;
	}
}

.footer-ctn-bottom {
	margin-top: 10rem;

	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	gap: 5rem;
}

.footer-text {
	color: var(--color-white);
	opacity: 0.5;
}

.footer-logos {
	display: flex;
	align-items: center;
	gap: 1.5rem;
}

.footer .logo-notary {
	height: 10.5rem;
	width: auto;
}

.footer .logo-notary path {
	fill: var(--color-white-logo-notary);
}

@media screen and (max-width: 800px) {
	/* not 650 to avoid overflow & to avoid footer ctn bottom to stretch */
	.footer {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}

	.footer-nav {
		flex-direction: column;
		gap: 5rem;
	}

	.footer-ctn-bottom {
		margin-top: 9rem;

		flex-direction: column-reverse;
		align-items: center;
		gap: 8rem;
	}

	.footer-text {
		text-align: center;
	}
}

/* 515px => Ecosia browser width */
@media screen and (max-width: 515px) {
	.footer-nav-ctn > * {
		display: none;
	}

	.footer-nav-ctn > :last-child {
		display: block;
	}

	.footer-logo-office {
		height: 9.5rem;
		width: auto;
	}

	.footer .logo-notary {
		height: 7.5rem;
		width: auto;
	}
}
</style>
