export default {
	tel: "03 10 45 16 84",
	mail: "office.saint-andre@10078.notaires.fr",
	networks: [
		{
			name: "instagram",
			link: "https://www.instagram.com/maitre_robin_cuisance",
		},
		{
			name: "linkedin",
			link: "https://www.linkedin.com/company/office-des-deux-cl%C3%A9s-ma%C3%AEtre-robin-cuisance",
		},
		{
			name: "facebook",
			link: "https://www.facebook.com/profile.php?id=61552576923354",
		},
	],
};
