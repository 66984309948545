import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
	faLocationDot,
	faClock,
	faSquareParking,
	faWheelchairMove,
	faEnvelope,
	faGlobe,
	faPhone,
} from "@fortawesome/free-solid-svg-icons";

import {
	faSquareInstagram,
	faSquareFacebook,
	faSquareTwitter,
	faLinkedin,
	faSquareYoutube,
} from "@fortawesome/free-brands-svg-icons";

library.add(
	faLocationDot,
	faClock,
	faSquareParking,
	faWheelchairMove,
	faEnvelope,
	faGlobe,
	faPhone,
	faSquareInstagram,
	faSquareFacebook,
	faSquareTwitter,
	faLinkedin,
	faSquareYoutube
);

import { Icon as IconifyIcon } from "@iconify/vue";

const app = createApp(App);

import { ItemsTypes } from "./navListItems.js";

app.config.globalProperties.ItemsTypes = ItemsTypes;

app
	.use(store)
	.use(router)
	.component("font-awesome-icon", FontAwesomeIcon)
	.component("iconify-icon", IconifyIcon)
	.mount("#app");
