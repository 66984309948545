<template>
	<a
		class="contact-network"
		:href="network.link"
		target="_blank"
		:title="textAlt"
		:aria-label="textAlt"
		><font-awesome-icon class="contact-network-icon" :icon="icon"
	/></a>
</template>

<script>
export default {
	name: "ContactNetwork",

	props: {
		network: String,
	},

	computed: {
		textAlt() {
			switch (this.network.name) {
				case "instagram":
					return "Consulter le compte Instagram de l'office";
				case "twitter":
					return "Consulter le compte Twitter de l'office";
				case "linkedin":
					return "Consulter le compte LinkedIn de l'office";
				case "facebook":
					return "Consulter la page Facebook de l'office";
				case "youtube":
					return "Consulter la chaîne Youtube de l'office";
				default:
					return "";
			}
		},

		icon() {
			switch (this.network.name) {
				case "instagram":
				case "facebook":
				case "twitter":
				case "youtube":
					return `fa-brands fa-square-${this.network.name}`;
				case "linkedin":
					return `fa-brands fa-${this.network.name}`;
				default:
					return "";
			}
		},
	},
};
</script>

<style>
.contact-network {
	font-size: 4rem;
	color: var(--color-secondary-dark);
}

.contact-network-icon {
	padding: 0 0.2rem;
	display: block;
}
</style>
