import externalNavLinks from "@/store/externalNavLinks.js";

const ItemsTypes = {
	LINK_INT: 0,
	LINK_EXT: 1,
	SUBLIST: 2,
};

const navListItems = [
	{
		type: ItemsTypes.LINK_INT,
		link: "home",
		name: "Accueil",
	},
	{
		type: ItemsTypes.SUBLIST,
		id: "aboutSublist",
		name: "A propos",
		items: [
			{
				type: ItemsTypes.LINK_INT,
				link: "team",
				name: "L'équipe",
			},
			{
				type: ItemsTypes.LINK_INT,
				link: "office",
				name: "L'office",
			},
			{
				type: ItemsTypes.LINK_INT,
				link: "news",
				name: "Actualités",
			},
		],
	},
	{
		type: ItemsTypes.LINK_INT,
		link: "expertises",
		name: "Expertises",
	},
	{
		type: ItemsTypes.SUBLIST,
		id: "infosSublist",
		name: "Informations & outils",
		items: [
			{
				type: ItemsTypes.LINK_INT,
				link: "infos",
				name: "Informations & outils",
			},
			{
				type: ItemsTypes.LINK_INT,
				link: "prices",
				name: "Tarifs",
			},
			{
				type: ItemsTypes.LINK_INT,
				link: "missions",
				name: "Missions du notaire",
			},
			{
				type: ItemsTypes.LINK_EXT,
				link: externalNavLinks.appointment,
				name: "Prendre rendez-vous en ligne",
			},
			{
				type: ItemsTypes.LINK_EXT,
				link: externalNavLinks.clientParticular,
				name: "Accès espace client particuliers",
			},
			{
				type: ItemsTypes.LINK_EXT,
				link: externalNavLinks.clientProfessional,
				name: "Accès espace client professionnels",
			},
		],
	},
	{
		type: ItemsTypes.LINK_INT,
		link: "contact",
		name: "Contact",
	},
];

export { ItemsTypes, navListItems };
