<template>
	<svg
		v-if="withoutText"
		class="logo-notary"
		width="297"
		height="255"
		viewBox="0 0 297 255"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M175.111 0C176.123 0.56 177.145 1.1093 178.14 1.692C180.109 2.8373 181.844 4.2827 182.868 6.3293C186.855 14.304 190.221 22.512 191.383 31.4373C191.703 33.8347 191.673 36.2627 191.257 38.684C190.769 41.508 187.825 44.32 184.336 42.7533C182.193 41.784 179.923 41.0867 177.719 40.2493C171.864 38.02 169.504 33.4547 169.112 27.512C168.793 22.6933 169.516 17.9653 170.323 13.2493C170.955 9.516 171.395 5.752 171.679 1.976C171.732 1.2827 171.835 0.6267 172.123 0H175.111Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M296.657 206.158C296.633 223.208 287.959 235.138 271.953 241.022C263.298 244.208 254.265 245.87 245.158 247.166C232.347 248.99 219.441 249.749 206.541 250.429C197.663 250.893 188.781 251.218 179.903 251.58C172.627 251.876 165.401 252.52 158.265 254.008C157.138 254.236 155.705 254.778 155.03 253.561C154.427 252.466 155.559 251.501 156.259 250.653C159.071 247.214 162.75 244.908 166.63 242.884C178.759 236.542 191.575 231.82 204.451 227.297C218.315 222.425 232.282 217.866 246.158 213.017C250.789 211.398 255.209 209.188 259.106 206.158C266.062 200.744 268.223 194.408 264.105 185.248C258.769 173.401 251.897 162.5 243.754 152.389C242.622 150.992 241.418 149.637 240.779 147.914C240.147 146.216 240.581 145.594 242.357 145.541C244.333 145.48 246.158 146.149 247.963 146.794C258.19 150.437 266.737 156.658 274.253 164.326C281.697 171.92 287.827 180.477 292.633 189.986C295.381 195.42 296.621 201.165 296.657 206.158Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M116.805 185.169C117.666 191.889 118.863 198.473 121.713 204.761C125.766 213.692 134.77 213.024 140.038 208.796C144.291 205.388 148.079 201.412 152.162 197.781C166.743 184.832 183.095 174.715 201.102 167.253C208.558 164.151 216.182 161.489 223.831 158.917C224.301 158.755 224.771 158.585 225.251 158.472C226.938 158.085 227.661 158.743 227.289 160.435C226.746 162.947 225.686 165.247 224.205 167.368C218.742 175.184 212.358 182.231 205.931 189.223C195.074 201.039 183.787 212.409 171.627 222.895C164.135 229.351 155.878 234.397 146.254 236.915C133.589 240.221 121.773 238.524 111.022 230.724C107.897 228.46 106.921 225.424 107.727 221.889C109.366 214.668 110.625 207.405 111.101 200.015C111.402 195.173 111.595 190.319 110.818 185.507C109.909 179.881 106.47 177.227 99.677 176.6C97.834 176.437 95.942 176.407 94.249 175.473C91.293 173.836 90.726 171.565 92.745 168.872C97.687 162.265 103.687 157.196 112.173 155.847C112.353 155.821 112.534 155.761 112.721 155.737C117.683 155.056 120.401 158.128 118.875 162.861C117.935 165.772 117.274 168.728 116.798 171.733C116.13 171.843 116.123 172.348 116.123 172.86V184.043C116.123 184.555 116.137 185.055 116.805 185.169Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M30.8569 95.6692C30.3209 91.3812 33.1749 86.7552 38.6809 83.5462C39.5949 83.0155 40.4499 82.3822 41.3539 81.8475C45.0629 79.6782 48.8529 77.6795 53.0739 76.6915C55.4579 76.1382 56.0549 76.4742 56.7059 78.8409C58.5829 85.6652 59.6209 92.6692 60.8419 99.6372C62.2939 108.003 63.6069 116.398 64.9859 124.781C66.6249 134.701 68.1789 144.625 69.9069 154.521C72.3769 168.698 74.9419 182.857 77.8089 196.955C81.1929 213.621 84.9509 230.194 89.4089 246.606C89.9549 248.622 89.3229 249.418 87.2749 249.389C85.9569 249.37 84.8369 248.906 83.9869 247.895C83.5489 247.37 83.1139 246.827 82.7949 246.226C78.5789 238.294 73.2309 231.115 67.9669 223.882C64.4569 219.059 62.7649 213.463 60.8729 207.959C54.6579 189.753 49.0929 171.335 43.6369 152.894C40.1849 141.229 36.7229 129.582 33.7589 117.782C32.1269 111.261 31.2779 104.618 30.8569 95.6692Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M204.349 142.549C194.267 142.09 183.583 140.561 173.839 135.081C169.557 132.665 166.009 129.383 162.919 125.613C160.993 123.265 161.191 122.686 163.914 121.535C168.887 119.446 173.839 117.319 178.717 114.995C184.589 112.201 188.111 107.678 189.743 101.498C192.286 91.8383 191.798 82.5275 186.234 73.9342C182.71 68.4835 177.543 65.2008 171.345 63.4662C170.291 63.1702 168.918 63.0808 168.81 61.6955C168.695 60.3275 169.87 59.6835 170.942 59.1368C172.935 58.1062 175.139 58.0342 177.301 57.9915C184.39 57.8408 191.154 59.0568 197.122 63.1835C203.927 67.8862 207.662 74.6075 209.582 82.4368C211.931 91.9773 212.051 101.491 208.691 110.851C207.414 114.405 205.277 117.475 202.855 120.319C196.893 127.335 201.109 131.447 206.535 133.718C210.087 135.206 213.887 135.947 217.466 137.351C218.689 137.826 220.61 138.014 220.586 139.537C220.55 141.254 218.538 141.217 217.237 141.578C213.305 142.651 209.251 142.578 204.349 142.549Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M0.96726 164.627C1.40226 147.745 5.30526 131.943 10.3633 116.368C11.2183 113.736 12.4713 111.339 14.4583 109.363C14.9413 108.883 15.4633 108.249 16.1993 108.599C16.8743 108.917 16.6143 109.657 16.5473 110.279C15.4633 120.5 15.0493 130.749 16.1153 140.988C17.4213 153.593 20.2653 165.717 28.0273 176.155C31.0383 180.201 34.7903 183.411 39.2653 185.767C40.7713 186.56 40.8382 187.079 39.5252 188.156C37.5252 189.795 35.1703 190.801 32.7193 191.517C28.9013 192.644 25.0223 193.421 20.9993 193.396C12.4473 193.355 6.40725 189.228 3.40725 181.279C1.77525 176.967 0.63726 168.836 0.96726 164.627Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M19.7098 37.7021C19.7268 29.2101 21.5228 21.0914 24.8538 13.2981C25.0508 12.8461 25.2498 12.3821 25.5218 11.9674C26.9238 9.84211 28.5258 9.74611 30.1398 11.7141C31.4948 13.3701 32.3868 15.2981 33.0498 17.3034C36.2348 26.8914 39.9318 36.3168 42.5348 46.0968C43.3058 49.0234 44.4198 52.0714 43.7988 55.0274C42.8718 59.4861 40.1198 63.0088 36.1508 65.2968C34.3438 66.3381 32.2838 66.2008 30.5678 64.7541C25.1178 60.1661 22.0938 54.1728 20.5708 47.3248C19.8658 44.1581 19.6618 40.9421 19.7098 37.7021Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M126.28 104.552C121.035 104.22 115.529 101.264 110.056 97.9404C104.695 94.6934 103.081 89.3144 101.317 83.9786C100.667 81.9973 101.509 81.052 103.54 81.256C114.151 82.3466 124.792 83.184 135.243 85.4494C139.379 86.3454 140.488 88.3164 139.433 92.4704C138.681 95.4584 137.363 98.1804 135.519 100.637C133.231 103.702 131.405 104.594 126.28 104.552Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M150.7 60.2686C150.712 64.3099 148.519 66.2126 143.979 65.4539C137.733 64.3992 133.084 60.6179 128.724 56.3832C124.249 52.0472 121.761 46.6392 120.309 40.6886C120.191 40.2126 120.172 39.7072 120.165 39.2126C120.129 37.0152 120.859 36.3766 123.025 36.9312C127.772 38.1352 132.409 39.6952 136.975 41.4712C139.172 42.3339 141.287 43.3686 143.352 44.5126C146.219 46.1032 148.013 48.4579 149.02 51.5712C149.893 54.2392 150.549 56.9366 150.7 60.2686Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M215.593 65.6882C214.311 65.7069 213.516 65.5442 212.836 65.0735C209.775 62.9909 208.096 59.9855 207.596 56.4309C207.216 53.6735 210.565 49.4095 213.835 48.0669C218.647 46.0909 223.609 45.3562 228.771 46.3255C231.819 46.9042 232.408 48.0309 230.897 50.6189C228.481 54.7575 225.856 58.7509 222.272 61.9962C220.225 63.8522 217.985 65.3749 215.593 65.6882Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M240.146 84.3709C242.048 84.3109 242.904 85.3334 242.494 87.7014C242.265 88.9654 241.826 90.1404 240.922 91.0124C237.785 94.0244 234.593 96.9754 230.504 98.7214C226.776 100.312 224.077 99.5774 221.77 96.2644C220.44 94.3554 219.513 92.2474 219.176 89.9414C218.814 87.4414 219.398 86.6524 221.88 86.2314C224.138 85.8454 226.414 85.5394 228.69 85.2684C230.54 85.0514 232.401 84.9314 234.256 84.7854C236.056 84.6474 237.85 84.5324 240.146 84.3709Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M116.806 185.169C116.138 185.055 116.125 184.555 116.125 184.043V172.86C116.125 172.348 116.132 171.843 116.8 171.733C116.529 176.208 116.589 180.688 116.806 185.169Z"
			fill="white"
		/>
	</svg>
	<svg
		v-else
		class="logo-notary"
		width="454"
		height="412"
		viewBox="0 0 454 412"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M0 352.416C1.7947 349.308 2.62 345.84 3.6374 342.461C10.3894 320.039 16.4227 297.417 21.7294 274.604C21.892 273.929 21.4947 272.775 22.8254 272.557C24.0734 272.352 24.3974 273.297 24.9147 274.027C32.9374 285.336 41.8027 295.991 50.5534 306.741C58.6294 316.665 66.9227 326.423 75.4321 335.981C75.9631 336.571 76.5161 337.124 77.3191 337.973C78.4871 333.56 79.6241 329.536 80.6071 325.471C83.5811 313.216 86.5731 300.965 88.9721 288.576C89.8571 284.024 90.6451 279.453 91.4531 274.883C91.5601 274.256 91.4761 273.545 92.4401 273.737C95.1991 274.279 97.9631 274.128 100.855 273.677C96.8551 286.125 93.6201 298.611 90.3031 311.077C86.5871 325.055 83.1051 339.093 79.9491 353.211C79.8291 353.753 80.1121 354.543 79.1311 354.62C78.2881 354.687 77.6251 354.565 77.0281 353.813C73.0067 348.748 68.9454 343.719 64.8814 338.703C51.668 322.381 38.2787 306.211 24.3547 289.997C23.4214 293.449 22.512 296.647 21.6867 299.875C17.8147 315.028 14.1054 330.223 10.9787 345.544C10.5334 347.731 10.1307 349.928 9.8227 352.132C9.708 352.995 9.4854 353.223 8.5827 353.199C5.7214 353.108 2.856 353.163 0 353.163V352.416Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M405.629 321.303C408.7 321.303 411.556 321.255 414.411 321.31C422.553 321.484 430.701 321.55 438.832 321.296C443.204 321.164 443.348 320.983 443.723 316.618C444.361 309.228 441.373 303.296 433.508 301.423C430.773 300.772 428.016 300.815 425.196 301.212C414.935 302.651 407.369 311.746 405.629 321.303ZM453.955 320.683C453.191 321.76 453.191 323.098 452.931 324.303C452.653 325.615 452.177 326.019 450.859 325.935C444.096 325.506 437.315 325.399 430.544 325.387C422.403 325.375 414.26 325.512 406.117 325.506C404.992 325.506 404.672 325.863 404.503 326.892C403.401 333.576 403.732 339.912 408.881 345.08C412.255 348.466 416.524 349.771 421.137 350.175C429.165 350.862 436.64 349.14 443.524 344.924C443.891 344.695 444.269 344.478 444.663 344.286C444.752 344.238 444.896 344.279 445.156 344.279C444.409 347.296 443.771 349.959 440.108 351.151C429.273 354.68 418.344 355.932 407.455 352.192C396.981 348.591 392.175 339.798 392.837 328.862C393.964 310.36 407.955 299.964 421.855 297.856C429.292 296.726 436.604 296.979 443.577 300.014C449.035 302.386 452.593 306.451 453.629 312.474C453.66 312.606 453.847 312.715 453.955 312.842V320.683Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M253.111 0C254.123 0.56 255.145 1.1093 256.14 1.692C258.109 2.8373 259.844 4.2827 260.868 6.3293C264.855 14.304 268.221 22.512 269.383 31.4373C269.703 33.8347 269.673 36.2627 269.257 38.684C268.769 41.508 265.825 44.32 262.336 42.7533C260.193 41.784 257.923 41.0867 255.719 40.2493C249.864 38.02 247.504 33.4547 247.112 27.512C246.793 22.6933 247.516 17.9653 248.323 13.2493C248.955 9.516 249.395 5.752 249.679 1.976C249.732 1.2827 249.835 0.6267 250.123 0H253.111Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M374.657 206.158C374.633 223.208 365.959 235.138 349.953 241.022C341.298 244.208 332.265 245.87 323.158 247.166C310.347 248.99 297.441 249.749 284.541 250.429C275.663 250.893 266.781 251.218 257.903 251.58C250.627 251.876 243.401 252.52 236.265 254.008C235.138 254.236 233.705 254.778 233.03 253.561C232.427 252.466 233.559 251.501 234.259 250.653C237.071 247.214 240.75 244.908 244.63 242.884C256.759 236.542 269.575 231.82 282.451 227.297C296.315 222.425 310.282 217.866 324.158 213.017C328.789 211.398 333.209 209.188 337.106 206.158C344.062 200.744 346.223 194.408 342.105 185.248C336.769 173.401 329.897 162.5 321.754 152.389C320.622 150.992 319.418 149.637 318.779 147.914C318.147 146.216 318.581 145.594 320.357 145.541C322.333 145.48 324.158 146.149 325.963 146.794C336.19 150.437 344.737 156.658 352.253 164.326C359.697 171.92 365.827 180.477 370.633 189.986C373.381 195.42 374.621 201.165 374.657 206.158Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M194.805 185.169C195.666 191.889 196.863 198.473 199.713 204.761C203.766 213.692 212.77 213.024 218.038 208.796C222.291 205.388 226.079 201.412 230.162 197.781C244.743 184.832 261.095 174.715 279.102 167.253C286.558 164.151 294.182 161.489 301.831 158.917C302.301 158.755 302.771 158.585 303.251 158.472C304.938 158.085 305.661 158.743 305.289 160.435C304.746 162.947 303.686 165.247 302.205 167.368C296.742 175.184 290.358 182.231 283.931 189.223C273.074 201.039 261.787 212.409 249.627 222.895C242.135 229.351 233.878 234.397 224.254 236.915C211.589 240.221 199.773 238.524 189.022 230.724C185.897 228.46 184.921 225.424 185.727 221.889C187.366 214.668 188.625 207.405 189.101 200.015C189.402 195.173 189.595 190.319 188.818 185.507C187.909 179.881 184.47 177.227 177.677 176.6C175.834 176.437 173.942 176.407 172.249 175.473C169.293 173.836 168.726 171.565 170.745 168.872C175.687 162.265 181.687 157.196 190.173 155.847C190.353 155.821 190.534 155.761 190.721 155.737C195.683 155.056 198.401 158.128 196.875 162.861C195.935 165.772 195.274 168.728 194.798 171.733C194.13 171.843 194.123 172.348 194.123 172.86V184.043C194.123 184.555 194.137 185.055 194.805 185.169Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M108.857 95.6692C108.321 91.3812 111.175 86.7552 116.681 83.5462C117.595 83.0155 118.45 82.3822 119.354 81.8475C123.063 79.6782 126.853 77.6795 131.074 76.6915C133.458 76.1382 134.055 76.4742 134.706 78.8409C136.583 85.6652 137.621 92.6692 138.842 99.6372C140.294 108.003 141.607 116.398 142.986 124.781C144.625 134.701 146.179 144.625 147.907 154.521C150.377 168.698 152.942 182.857 155.809 196.955C159.193 213.621 162.951 230.194 167.409 246.606C167.955 248.622 167.323 249.418 165.275 249.389C163.957 249.37 162.837 248.906 161.987 247.895C161.549 247.37 161.114 246.827 160.795 246.226C156.579 238.294 151.231 231.115 145.967 223.882C142.457 219.059 140.765 213.463 138.873 207.959C132.658 189.753 127.093 171.335 121.637 152.894C118.185 141.229 114.723 129.582 111.759 117.782C110.127 111.261 109.278 104.618 108.857 95.6692Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M282.349 142.549C272.267 142.09 261.583 140.561 251.839 135.081C247.557 132.665 244.009 129.383 240.919 125.613C238.993 123.265 239.191 122.686 241.914 121.535C246.887 119.446 251.839 117.319 256.717 114.995C262.589 112.201 266.111 107.678 267.743 101.498C270.286 91.8383 269.798 82.5275 264.234 73.9342C260.71 68.4835 255.543 65.2008 249.345 63.4662C248.291 63.1702 246.918 63.0808 246.81 61.6955C246.695 60.3275 247.87 59.6835 248.942 59.1368C250.935 58.1062 253.139 58.0342 255.301 57.9915C262.39 57.8408 269.154 59.0568 275.122 63.1835C281.927 67.8862 285.662 74.6075 287.582 82.4368C289.931 91.9773 290.051 101.491 286.691 110.851C285.414 114.405 283.277 117.475 280.855 120.319C274.893 127.335 279.109 131.447 284.535 133.718C288.087 135.206 291.887 135.947 295.466 137.351C296.689 137.826 298.61 138.014 298.586 139.537C298.55 141.254 296.538 141.217 295.237 141.578C291.305 142.651 287.251 142.578 282.349 142.549Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M78.9673 164.627C79.4023 147.745 83.3053 131.943 88.3633 116.368C89.2183 113.736 90.4713 111.339 92.4583 109.363C92.9413 108.883 93.4633 108.249 94.1993 108.599C94.8743 108.917 94.6143 109.657 94.5473 110.279C93.4633 120.5 93.0493 130.749 94.1153 140.988C95.4213 153.593 98.2653 165.717 106.027 176.155C109.038 180.201 112.79 183.411 117.265 185.767C118.771 186.56 118.838 187.079 117.525 188.156C115.525 189.795 113.17 190.801 110.719 191.517C106.901 192.644 103.022 193.421 98.9993 193.396C90.4473 193.355 84.4073 189.228 81.4073 181.279C79.7753 176.967 78.6373 168.836 78.9673 164.627Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M120.409 333.656C120.469 334.817 120.464 335.817 120.59 336.8C121.313 342.268 123.445 347.092 128.805 349.2C136.526 352.234 144.416 351.921 151.449 347.321C162.736 339.932 167.241 328.873 166.789 315.733C166.62 310.769 164.02 306.789 159.664 304.169C151.474 299.244 139.688 300.562 131.949 307.312C123.914 314.31 120.993 323.568 120.409 333.656ZM148.216 297.206C155.526 297.43 161.946 298.256 167.782 301.573C174.24 305.253 177.744 310.849 178.04 318.202C178.586 331.794 172.192 341.713 160.958 348.725C153.172 353.584 144.488 354.969 135.436 354.488C130.257 354.21 125.216 353.373 120.578 350.886C113.374 347.02 109.828 340.858 109.334 332.83C108.484 319.117 117.928 306.09 131.377 300.508C137.025 298.164 142.921 297.328 148.216 297.206Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M276.599 324.351C268.746 325.604 261.12 326.507 253.834 329.006C251.082 329.952 248.54 331.372 246.384 333.439C243.348 336.336 242.156 339.792 243.588 343.719C244.968 347.514 248.323 348.995 252.075 349.41C260.247 350.308 266.578 346.719 271.938 340.936C272.347 340.498 272.474 340.015 272.594 339.455C273.727 334.456 274.792 329.434 276.599 324.351ZM245.36 309.692C245.53 309.163 245.758 308.699 245.806 308.223C246.246 304.115 248.907 302.236 252.503 300.815C260.971 297.472 269.564 296.19 278.515 298.291C280.58 298.78 282.478 299.706 284.235 300.887C288.855 303.971 290.482 308.524 289.102 314.426C287.319 322.074 284.82 329.518 282.844 337.107C282.315 339.155 281.995 341.227 281.832 343.335C281.531 347.26 284.375 350.332 289.595 348.754C290.066 348.616 290.494 348.38 290.944 348.188C292.011 350.699 292.004 350.688 289.614 351.706C286.284 353.127 282.827 353.658 279.238 353.223C274.936 352.699 272.72 350.536 272.034 346.152C270.167 347.43 268.396 348.767 266.511 349.911C258.61 354.728 250.207 356.035 241.379 353.054C237.103 351.61 233.814 348.742 233.038 344.094C232.188 339.01 233.928 334.722 238.103 331.464C243.24 327.454 249.256 325.7 255.484 324.411C261.06 323.248 266.74 322.628 272.328 321.539C277.69 320.496 279.996 316.738 279.05 311.288C278.219 306.506 275.376 303.826 270.052 302.766C261.771 301.116 254.604 303.41 248.335 308.86C247.486 309.602 246.679 310.155 245.36 309.692Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M251.139 393.194C257.354 393.014 263.564 393.069 269.7 394.268C271.478 394.604 273.2 395.17 274.664 396.248C276.536 397.628 276.952 399.573 275.97 401.705C275.278 403.198 274.242 404.44 272.983 405.464C268.707 408.973 264.094 411.221 258.258 411.112C247.472 410.92 236.854 409.378 226.224 407.866C218.31 406.746 210.403 405.524 202.622 403.638C200.875 403.216 199.152 402.693 197.442 402.15C196.828 401.958 196.015 401.712 195.996 400.904C195.979 400.03 196.87 399.921 197.467 399.722C201.308 398.446 205.302 397.857 209.276 397.224C222.098 395.182 235.004 393.845 247.971 393.206C249.024 393.153 250.079 393.194 251.139 393.194Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M97.7098 37.7021C97.7268 29.2101 99.5228 21.0914 102.854 13.2981C103.051 12.8461 103.25 12.3821 103.522 11.9674C104.924 9.84211 106.526 9.74611 108.14 11.7141C109.495 13.3701 110.387 15.2981 111.05 17.3034C114.235 26.8914 117.932 36.3168 120.535 46.0968C121.306 49.0234 122.42 52.0714 121.799 55.0274C120.872 59.4861 118.12 63.0088 114.151 65.2968C112.344 66.3381 110.284 66.2008 108.568 64.7541C103.118 60.1661 100.094 54.1728 98.5708 47.3248C97.8658 44.1581 97.6618 40.9421 97.7098 37.7021Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M228.313 299.772C228.152 300.79 227.633 302.007 227.676 303.332C227.724 304.615 226.7 304.211 226.061 304.199C222.146 304.115 218.232 304.012 214.324 303.826C213.089 303.766 212.654 304.18 212.366 305.379C209.842 315.89 207.03 326.331 204.784 336.902C204.568 337.932 204.332 338.968 204.236 340.015C203.58 346.863 208.644 351.41 215.678 350.416C218.268 350.048 220.701 349.248 223.104 348.236C223.489 350.386 222.881 351.74 220.792 352.487C214.552 354.716 208.222 355.427 201.821 353.494C194.973 351.428 192.461 346.442 194.304 339.07C197.134 327.808 199.929 316.534 202.825 305.283C203.138 304.06 202.994 303.79 201.748 303.826C199.268 303.911 196.792 303.85 194.201 303.85C194.322 302.567 194.912 301.38 194.828 300.086C194.761 299.08 195.533 299.43 195.996 299.448C198.044 299.532 200.104 299.562 202.133 299.81C203.549 299.983 203.898 299.308 204.114 298.14C204.712 294.984 205.452 291.852 205.976 288.679C206.157 287.614 206.621 287.27 207.56 286.999C211.113 285.963 214.642 284.831 218.16 283.74C218.636 284.198 218.214 284.559 218.105 284.939C216.829 289.456 215.581 293.986 214.257 298.483C213.932 299.586 214.184 299.814 215.293 299.803C219.629 299.736 223.977 299.772 228.313 299.772Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M367.037 298.195C365.592 302.399 364.086 306.319 363.948 310.836C365.104 309.638 366.285 308.463 367.41 307.247C370.808 303.572 374.464 300.284 379.269 298.502C381.341 297.726 383.472 297.544 385.634 297.364C386.514 297.291 386.52 297.76 386.4 298.4C385.9 301.014 385.237 303.598 385.237 306.295C385.237 307.367 384.249 307.596 383.49 307.343C378.764 305.759 374.354 306.367 370.145 308.975C365.429 311.896 362.917 316.383 361.358 321.472C358.545 330.632 356.334 339.948 354.305 349.308C353.481 353.103 353.577 353.12 349.716 353.127C347.722 353.139 345.736 353.114 343.748 353.18C342.833 353.211 342.556 353.132 342.912 352.091C346.344 342.02 348.94 331.716 351.45 321.395C353.24 314.064 354.92 306.704 356.118 299.255C356.257 298.423 356.552 298.164 357.305 298.298C360.454 298.863 363.605 298.748 367.037 298.195Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M204.28 104.552C199.035 104.22 193.529 101.264 188.056 97.9404C182.695 94.6934 181.081 89.3144 179.317 83.9786C178.667 81.9973 179.509 81.052 181.54 81.256C192.151 82.3466 202.792 83.184 213.243 85.4494C217.379 86.3454 218.488 88.3164 217.433 92.4704C216.681 95.4584 215.363 98.1804 213.519 100.637C211.231 103.702 209.405 104.594 204.28 104.552Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M332.011 298.412C329.518 306.452 327.127 314.239 325.061 322.123C322.459 332.113 319.899 342.111 318.123 352.289C317.991 353.048 317.834 353.295 317.003 353.283C313.407 353.211 309.813 353.253 306.023 353.253C307.861 348.483 309.403 343.751 310.751 338.961C314.323 326.224 317.454 313.372 319.966 300.387C320.225 299.032 320.442 298.207 322.362 298.532C325.458 299.049 328.645 298.851 332.011 298.412Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M228.7 60.2686C228.712 64.3099 226.519 66.2126 221.979 65.4539C215.733 64.3992 211.084 60.6179 206.724 56.3832C202.249 52.0472 199.761 46.6392 198.309 40.6886C198.191 40.2126 198.172 39.7072 198.165 39.2126C198.129 37.0152 198.859 36.3766 201.025 36.9312C205.772 38.1352 210.409 39.6952 214.975 41.4712C217.172 42.3339 219.287 43.3686 221.352 44.5126C224.219 46.1032 226.013 48.4579 227.02 51.5712C227.893 54.2392 228.549 56.9366 228.7 60.2686Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M293.593 65.6882C292.311 65.7069 291.516 65.5442 290.836 65.0735C287.775 62.9909 286.096 59.9855 285.596 56.4309C285.216 53.6735 288.565 49.4095 291.835 48.0669C296.647 46.0909 301.609 45.3562 306.771 46.3255C309.819 46.9042 310.408 48.0309 308.897 50.6189C306.481 54.7575 303.856 58.7509 300.272 61.9962C298.225 63.8522 295.985 65.3749 293.593 65.6882Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M318.146 84.3709C320.048 84.3109 320.904 85.3334 320.494 87.7014C320.265 88.9654 319.826 90.1404 318.922 91.0124C315.785 94.0244 312.593 96.9754 308.504 98.7214C304.776 100.312 302.077 99.5774 299.77 96.2644C298.44 94.3554 297.513 92.2474 297.176 89.9414C296.814 87.4414 297.398 86.6524 299.88 86.2314C302.138 85.8454 304.414 85.5394 306.69 85.2684C308.54 85.0514 310.401 84.9314 312.256 84.7854C314.056 84.6474 315.85 84.5324 318.146 84.3709Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M330.983 285.554C327.393 285.512 324.587 283.242 324.345 280.314C324.003 276.23 327.851 273.996 331.507 274.106C332.18 274.128 332.861 274.298 333.512 274.484C336.228 275.279 338.149 277.976 337.812 280.476C337.421 283.391 334.451 285.595 330.983 285.554Z"
			fill="white"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M194.806 185.169C194.138 185.055 194.125 184.555 194.125 184.043V172.86C194.125 172.348 194.132 171.843 194.8 171.733C194.529 176.208 194.589 180.688 194.806 185.169Z"
			fill="white"
		/>
	</svg>
</template>

<script>
export default {
	name: "LogoNotary",

	props: {
		withoutText: Boolean,
	},
};
</script>
