<template>
	<ul
		:id="navListId"
		class="nav-list"
		:class="{ 'sublist-active': activeSublist !== null }"
	>
		<li v-for="item in navListItems" :key="navListItems.indexOf(item)">
			<NavSublist
				v-if="item.type === ItemsTypes.SUBLIST"
				:header-mode="headerMode"
				:nav-sublist-id="item.id"
				:nav-sublist-btn-text="item.name"
				:active-sublist="activeSublist"
				:active-sublist-btn="activeSublistBtn"
				@update-active-sublist="updateActiveSublist"
			>
				<li
					v-for="sublistItem in item.items"
					:key="item.items.indexOf(sublistItem)"
				>
					<NavLink class="nav-link" :item="sublistItem" />
				</li>
			</NavSublist>
			<NavLink class="nav-link nav-link-primary" v-else :item="item" />
		</li>
	</ul>
</template>

<script>
import NavSublist from "./NavSublist.vue";
import NavLink from "./NavLink.vue";
import { navListItems } from "@/navListItems.js";
import isClickInsideOneOfThoseElements from "@/isClickInsideOneOfThoseElements";

export default {
	name: "NavList",

	components: {
		NavSublist,
		NavLink,
	},

	emits: ["hideNavList"],

	props: {
		headerMode: String,
		isNavListVisible: Boolean,
		navListId: String,
	},

	data() {
		return {
			activeSublistBtn: "",
			activeSublist: null,
			navListItems,
		};
	},

	mounted() {
		this.setupNav();
	},

	watch: {
		$route() {
			this.setupNav();
			if (this.headerMode === "extended") {
				this.activeSublist = null;
			}
		},

		isNavListVisible() {
			if (this.headerMode === "narrow" && this.isNavListVisible) {
				window.addEventListener("click", this.onWindowClick);
			} else if (this.headerMode === "narrow" && !this.isNavListVisible) {
				setTimeout(() => (this.activeSublist = null), 300); // wait until navListCtn transition is finished
			} else {
				this.activeSublist = null;
				window.removeEventListener("click", this.onWindowClick);
			}
		},
	},

	methods: {
		updateActiveSublist(sublist, isVisible) {
			this.activeSublist = isVisible ? sublist : null;
		},

		setupNav() {
			this.handleActiveSublistBtn();
		},

		handleActiveSublistBtn() {
			switch (this.$route.name) {
				case "team":
				case "office":
				case "news":
					this.activeSublistBtn = "about";
					break;
				case "infos":
				case "prices":
				case "missions":
					this.activeSublistBtn = "infos";
					break;
				default:
					this.activeSublistBtn = "";
			}
		},

		onWindowClick(e) {
			const avoidedElements = [document.querySelector("#header")];

			if (isClickInsideOneOfThoseElements(e, avoidedElements)) return;

			this.$emit("hideNavList");
		},
	},

	unmounted() {
		window.removeEventListener("click", this.onWindowClick);
	},
};
</script>

<style>
.nav-list {
	line-height: unset;
	height: 100%;

	display: flex;
	gap: 3.5rem;
}

.nav-list > * {
	position: relative;
}

.nav-link {
	text-decoration: none;
	letter-spacing: 0.05em;
	color: var(--color-white);
	white-space: nowrap;

	position: relative;
}

.nav-link:focus-visible {
	outline: none;
}

.nav-link:focus-visible > :only-child {
	width: fit-content;
	outline: 1px auto Highlight;
	outline: 1px auto -webkit-focus-ring-color;
}

.nav-link-primary {
	height: 100%;

	display: flex;
	align-items: center;
	position: relative;
}

.nav-link-primary::before {
	content: "";
	width: 100%;
	height: 0.2rem;
	background-color: var(--color-primary);
	transform: scaleX(0);
	transition: transform 0.3s;

	position: absolute;
	top: 0;
}

@media (any-hover: hover) {
	.nav-link-primary:hover::before {
		transform: scaleX(1);
	}
}

.nav-link-primary.router-link-active {
	color: var(--color-primary);
}

.nav-link-primary.router-link-active::before {
	transform: scaleX(1);
}

@media screen and (max-width: 1000px) {
	.nav-list {
		height: unset;
		box-shadow: 0 15px 15px rgba(0, 0, 0, 0.4);
		background-color: var(--color-secondary-darker);

		padding-top: 0.5rem;
		padding-bottom: 1rem;

		flex-direction: column;
		gap: 0;
		transition: transform 0.3s ease-out;
	}

	.nav-list.sublist-active {
		transform: translateX(-100%);
	}

	.nav-list > * {
		position: unset;
	}

	.nav-link {
		padding: 2rem var(--margin-sides) 2rem var(--margin-sides);
	}

	.nav-link-primary::before {
		display: none;
	}

	@media (any-hover: hover) {
		.nav-link-primary:hover {
			color: var(--color-primary);
		}
	}
}
</style>
