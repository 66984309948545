<template>
	<a
		:href="`mailto:${contacts.mail}`"
		title="Envoyer un mail à l'office"
		:aria-label="`Envoyer un mail à l'office à l'adresse ${contacts.mail}`"
		>{{ contacts.mail }}</a
	>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "ContactMail",

	computed: {
		...mapGetters(["contacts"]),
	},
};
</script>
