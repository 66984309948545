<template>
	<nav id="nav" class="header-nav" ref="nav" aria-label="Principale">
		<NavListToggleBtn
			class="header-btn-toggle-nav"
			ref="btnToggleNav"
			:is-nav-list-visible="isNavListVisible"
			:nav-list-id="navListId"
			@toggle-nav-list="$emit('toggleNavList')"
		/>
		<Transition name="nav-list-ctn">
			<div class="nav-list-ctn" v-show="isNavListVisible">
				<NavList
					:header-mode="headerMode"
					:is-nav-list-visible="isNavListVisible"
					:nav-list-id="navListId"
					@hide-nav-list="$emit('hideNavList')"
				/>
			</div>
		</Transition>
	</nav>
</template>

<script>
import NavListToggleBtn from "./NavListToggleBtn.vue";
import NavList from "./NavList.vue";

export default {
	name: "HeaderNav",

	components: {
		NavListToggleBtn,
		NavList,
	},

	props: {
		headerMode: String,
		isNavListVisible: Boolean,
	},

	emits: ["toggleNavList", "hideNavList"],

	data() {
		return {
			navListId: "navList",
		};
	},
};
</script>

<style>
.header-nav {
	margin-left: 5rem;
	justify-self: flex-end;
	height: 100%;
}

.header-btn-toggle-nav {
	justify-self: flex-end;
	display: none;
}

.nav-list-ctn {
	height: 100%;
}

@media screen and (max-width: 1000px) {
	.header-nav {
		display: flex;
		align-items: center;
	}

	.header-btn-toggle-nav {
		display: block;
	}

	.nav-list-ctn {
		height: unset;

		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
	}

	.nav-list-ctn-enter-active,
	.nav-list-ctn-leave-active {
		transform-origin: top;
		transition: transform 0.3s ease-out, opacity 0.2s ease-out;
	}

	.nav-list-ctn-enter-from,
	.nav-list-ctn-leave-to {
		transform: scaleY(0.001);
		opacity: 0;
	}
}
</style>
