<template>
	<RouterLink v-if="item.type === ItemsTypes.LINK_INT" :to="{ name: item.link }"
		><div>{{ item.name }}</div></RouterLink
	>
	<NavLinkExternal
		v-else-if="item.type === ItemsTypes.LINK_EXT"
		:link="item.link"
		:text="item.name"
	/>
</template>

<script>
import NavLinkExternal from "./NavLinkExternal.vue";

export default {
	components: {
		NavLinkExternal,
	},

	props: {
		item: Object,
	},
};
</script>
