<template>
	<a
		class="contact-tel"
		:href="`tel:${contacts.tel}`"
		title="Appeler l'office"
		:aria-label="`Appeler l'office au ${contacts.tel}`"
		>{{ contacts.tel }}</a
	>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "ContactTel",

	computed: {
		...mapGetters(["contacts"]),
	},
};
</script>

<style>
.contact-tel {
	white-space: nowrap;
}
</style>
