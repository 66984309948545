<template>
	<h1 class="view-title">{{ title }}</h1>
</template>

<script>
export default {
	name: "ViewTitle",

	props: {
		title: String,
	},
};
</script>

<style>
.view-title {
	background-color: var(--color-secondary-darker);

	text-align: center;
	padding: calc(var(--header-height) + 15rem) var(--margin-sides) 6.5rem;
}

@media screen and (max-width: 650px) {
	.view-title {
		padding-top: calc(var(--header-height) + 10rem);
		padding-bottom: 4.5rem;
	}
}
</style>
