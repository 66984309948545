export default [
	{
		id: 1,
		title: "Maître Robin CUISANCE",
		subtitle: "Notaire",
		text: [
			"Maître Robin CUISANCE a été nommé notaire suivant arrêté du garde des sceaux, ministre de la justice en date du 26 août 2022. Il a ensuite prêté serment au Tribunal judiciaire de Troyes le 26 septembre 2022.",
			"Il a obtenu son diplôme supérieur du notariat lors de l’année 2019/2020 à l’Université de Lorraine et a fait son entrée dans la profession en 2016.",
		],
		img: {
			filename: "robin-cuisance.jpg",
			alt: "Maître Robin CUISANCE dans son bureau",
			width: "586",
			height: "700",
		},
	},
	{
		id: 2,
		title: "Camille SERRE",
		subtitle: "Secrétaire formaliste",
		text: ["Camille SERRE est secrétaire formaliste, elle exerce depuis 2019."],
		img: {
			filename: "camille-serre.jpg",
			alt: "La secrétaire formaliste Camille SERRE dans son bureau",
			width: "407",
			height: "470",
		},
	},
];
