<template>
	<section v-if="listTitle">
		<h4 class="footer-list-title">{{ listTitle }}</h4>
		<ul class="footer-list">
			<slot></slot>
		</ul>
	</section>
	<ul v-else class="footer-list">
		<slot></slot>
	</ul>
</template>

<script>
export default {
	name: "FooterList",

	props: {
		listTitle: String,
	},
};
</script>

<style>
.footer-list {
	width: fit-content;

	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: flex-start;
}

.footer-list-title {
	font-weight: 600;

	margin-bottom: 2rem;
}
</style>
