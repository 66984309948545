import { createStore } from "vuex";
import contacts from "./contacts";
import teamMembers from "./teamMembers";
import externalNavLinks from "./externalNavLinks";

const state = {
	contacts,
	teamMembers,
	externalNavLinks,
};

const getters = {
	contacts: state => state.contacts,
	teamMembers: state => state.teamMembers,
	externalNavLinks: state => state.externalNavLinks,
};

export default createStore({
	state,
	getters,
	mutations: {},
	actions: {},
	modules: {},
});
