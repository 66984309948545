<template>
	<AppHeader ref="header" />
	<main>
		<ViewTitle
			v-if="this.$route.name !== 'home' && this.$route.name !== 'not-found'"
			:title="this.$route.meta.title"
		/>
		<router-view />
	</main>
	<AppFooter />
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import ViewTitle from "@/components/ViewTitle.vue";

export default {
	name: "App",

	components: {
		AppHeader,
		AppFooter,
		ViewTitle,
	},

	mounted() {
		this.calculateScrollbarWidth();
		window.addEventListener("resize", this.calculateScrollbarWidth);
	},

	watch: {
		$route() {
			this.calculateScrollbarWidth();
		},
	},

	unmounted() {
		window.removeEventListener("resize", this.calculateScrollbarWidth);
	},

	methods: {
		calculateScrollbarWidth() {
			document.documentElement.style.setProperty(
				"--scrollbar-width",
				window.innerWidth - document.documentElement.clientWidth + "px"
			);
			document.documentElement.style.setProperty(
				"--inner-width",
				document.documentElement.clientWidth + "px"
			);
		},
	},
};
</script>

<style>
:root {
	--color-primary: #d3a95e;
	--color-secondary-darker: #040d1b;
	--color-secondary-dark: #1e252f;
	--color-secondary: #a5a8ac;
	--color-secondary-light: #f4f7fa;
	--color-white: #ffffff;
	--color-white-logo-notary: #e4edfa;

	--margin-sides: 7vw;
}

*,
::before,
::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	/* font-size: 62.5%; */
	font-size: 10px;
}

body {
	font-family: "Inter", sans-serif;
}

h1 {
	font-family: "Playfair Display", serif;
	font-size: 6.4rem;
	font-weight: 400;
	color: var(--color-white);
}

h2 {
	--border-height: 4px;

	font-family: "Playfair Display", serif;
	font-size: 5.4rem;
	font-weight: 600;
	color: var(--color-secondary-darker);
	border-top: var(--border-height) solid transparent;

	position: relative;
}

h2::before {
	content: "";
	background-color: var(--color-primary);
	width: 1.25em;
	height: var(--border-height);
	bottom: 100%;

	position: absolute;
}

h3 {
	font-family: "Playfair Display", serif;
	font-size: 3.2rem;
	font-weight: 600;
	color: var(--color-secondary-darker);
}

p {
	font-size: 1.6rem;
	color: var(--color-secondary-dark);
	line-height: 190%;
}

p + p {
	margin-top: 2em;
}

ul {
	list-style-type: none;
	font-size: 1.6rem;
	color: var(--color-secondary-dark);
	line-height: 190%;
}

p + ul {
	margin-top: 2em;
}

ul.bulleted {
	padding-left: 9px;
}

ul.bulleted > *::marker {
	content: "•";
}

ul.bulleted > * {
	padding-left: 1rem;
}

.line-vertical,
.line-horizontal {
	background-color: var(--color-secondary);
}

.line-vertical {
	width: 1px;
	height: 100%;
}

.line-horizontal {
	height: 1px;
	width: 100%;
}

@media screen and (max-width: 650px) {
	h1 {
		font-size: 4.8rem;
	}

	h2 {
		--border-height: 3px;

		font-size: 3.8rem;
	}

	h3 {
		font-size: 2.4rem;
	}
}
</style>
