<template>
	<NavSublistToggleBtn
		ref="btn"
		v-bind="navSublistToggleBtnProps"
		:is-nav-sublist-btn-active="isNavSublistBtnActive"
		:is-nav-sublist-visible="isNavSublistVisible"
		@toggle-nav-sublist="toggleNavSublist"
		:aria-hidden="isNavSublistVisible && headerMode === 'narrow'"
		:tabindex="isNavSublistVisible && headerMode === 'narrow' ? '-1' : ''"
	/>
	<Transition name="nav-sublist">
		<ul
			:id="navSublistId"
			class="nav-sublist"
			ref="sublist"
			v-show="isNavSublistVisible"
		>
			<li>
				<NavSublistToggleBtn
					v-bind="navSublistToggleBtnProps"
					:is-nav-sublist-btn-active="isNavSublistBtnActive"
					:is-nav-sublist-visible="isNavSublistVisible"
					@toggle-nav-sublist="toggleNavSublist"
				/>
			</li>
			<slot></slot>
		</ul>
	</Transition>
</template>

<script>
import NavSublistToggleBtn from "./NavSublistToggleBtn.vue";
import isClickInsideOneOfThoseElements from "@/isClickInsideOneOfThoseElements.js";

export default {
	name: "NavSublist",

	components: {
		NavSublistToggleBtn,
	},

	props: {
		navSublistBtnText: {
			type: String,
			default: "Sublist",
		},

		activeSublistBtn: String,
		headerMode: String,
		activeSublist: null,
		navSublistId: String,
	},

	emits: ["updateActiveSublist"],

	data() {
		return {
			isNavSublistVisible: false,
			navSublistToggleBtnProps: {
				navSublistId: this.navSublistId,
				navSublistBtnText: this.navSublistBtnText,
			},
		};
	},

	computed: {
		isNavSublistBtnActive() {
			return `${this.activeSublistBtn}Sublist` === this.navSublistId;
		},
	},

	watch: {
		activeSublist() {
			this.isNavSublistVisible = this.activeSublist === this;

			if (this.headerMode === "extended") {
				if (this.isNavSublistVisible) {
					window.addEventListener("click", this.onWindowClick);
				} else {
					window.removeEventListener("click", this.onWindowClick);
				}
			}
		},
	},

	methods: {
		toggleNavSublist() {
			this.$emit("updateActiveSublist", this, !this.isNavSublistVisible);
		},

		hideNavSublist() {
			this.$emit("updateActiveSublist", this, false);
		},

		onWindowClick(e) {
			const avoidedElements = [this.$refs.sublist, this.$refs.btn];

			if (isClickInsideOneOfThoseElements(e, avoidedElements)) return;

			this.hideNavSublist();
		},
	},

	unmounted() {
		window.removeEventListener("click", this.onWindowClick);
	},
};
</script>

<style>
.nav-sublist {
	line-height: unset;
	background-color: var(--color-secondary-darker);
	display: flex;
	flex-direction: column;
	border: 2px solid var(--color-secondary-dark);
	border-radius: 5px;

	position: absolute;
	top: calc(100% - 1rem);
	right: 0;
	padding: 1.5rem 0 1.5rem;
}

.nav-sublist-enter-active,
.nav-sublist-leave-active {
	transition: transform 0.3s ease-out, opacity 0.2s ease-out;
	transform-origin: top;
}

.nav-sublist-enter-from,
.nav-sublist-leave-to {
	transform: scaleY(0);
	opacity: 0;
}

.nav-sublist .nav-sublist-toggle-btn {
	display: none;
}

.nav-sublist .nav-link {
	width: 100%;

	padding: 1rem 5rem 1rem 3rem;
}

.nav-sublist .nav-link {
	display: inline-block;
}

@media (any-hover: hover) {
	.nav-sublist .nav-link:hover {
		color: var(--color-primary);
	}
}

@media screen and (max-width: 1000px) {
	.nav-sublist {
		border: unset;
		border-radius: unset;
		width: 100%;
		box-shadow: 0 15px 15px rgba(0, 0, 0, 0.4);

		top: 0;
		left: 100%;
		right: unset;
		padding: 0.5rem 0 1rem;
	}

	.nav-sublist-enter-active {
		transition: unset;
	}

	.nav-sublist-leave-active {
		transition-duration: 0s;
		transition-delay: 0.3s;
	}

	.nav-sublist .nav-sublist-toggle-btn {
		border-bottom: 2px solid var(--color-secondary-dark);
		display: block;
		margin-bottom: 0.5rem;
	}

	.nav-sublist .nav-sublist-toggle-btn-content {
		width: fit-content;

		flex-direction: row-reverse;
		gap: 1.5rem;
		justify-content: unset;
	}

	.nav-sublist .nav-link {
		padding: 2rem var(--margin-sides);
	}
}
</style>
