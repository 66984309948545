<template>
	<button
		class="nav-sublist-toggle-btn"
		:class="{ active: isNavSublistBtnActive }"
		:aria-expanded="isNavSublistVisible"
		:aria-controls="navSublistId"
		@click.stop="$emit('toggleNavSublist')"
	>
		<div class="nav-sublist-toggle-btn-content">
			<span>{{ navSublistBtnText }}</span>
			<iconify-icon
				class="nav-sublist-toggle-btn-icon"
				:class="{ active: isNavSublistVisible }"
				icon="mdi:chevron-down"
			/>
		</div>
	</button>
</template>

<script>
export default {
	name: "NavSublistToggleBtn",

	props: {
		navSublistBtnText: {
			type: String,
			default: "Sublist",
		},

		isNavSublistBtnActive: {
			type: Boolean,
			default: false,
		},

		isNavSublistVisible: {
			type: Boolean,
			default: false,
		},

		navSublistId: String,
	},

	emits: ["toggleNavSublist"],
};
</script>

<style>
.nav-sublist-toggle-btn {
	border: unset;
	background-color: unset;
	color: var(--color-white);
	font-size: 1.6rem;
	letter-spacing: 0.05em;
	font-family: inherit;
	height: 100%;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;

	position: relative;
}

.nav-sublist-toggle-btn::before {
	content: "";
	width: 100%;
	height: 0.2rem;
	background-color: var(--color-primary);
	transform: scaleX(0);
	transition: transform 0.3s;

	position: absolute;
	top: 0;
	left: 0;
}

@media (any-hover: hover) {
	.nav-sublist-toggle-btn:hover::before {
		transform: scaleX(1);
	}
}

.nav-sublist-toggle-btn.active {
	color: var(--color-primary);
}

.nav-sublist-toggle-btn.active::before {
	transform: scaleX(1);
}

.nav-sublist-toggle-btn:focus-visible {
	outline: none;
}

.nav-sublist-toggle-btn:focus-visible .nav-sublist-toggle-btn-content {
	outline: 1px auto Highlight;
	outline: 1px auto -webkit-focus-ring-color;
}

.nav-sublist-toggle-btn-content {
	width: fit-content;

	display: flex;
	align-items: center;
	gap: 0.4rem;
}

.nav-sublist-toggle-btn-icon {
	width: 2rem;
	height: auto;
	transition: transform 0.3s;
}

.nav-sublist-toggle-btn-icon.active {
	transform: rotateX(180deg);
}

@media screen and (max-width: 1000px) {
	.nav-sublist-toggle-btn {
		width: 100%;

		padding: 2rem var(--margin-sides);
	}

	.nav-sublist-toggle-btn::before {
		display: none;
	}

	@media (any-hover: hover) {
		.nav-sublist-toggle-btn:hover {
			color: var(--color-primary);
		}
	}

	.nav-sublist-toggle-btn-content {
		width: 100%;

		justify-content: space-between;
	}

	.nav-sublist-toggle-btn-icon {
		transform: rotateZ(-90deg);
	}

	.nav-sublist-toggle-btn-icon.active {
		transform: rotateZ(-90deg) rotateX(180deg);
	}
}
</style>
