<template>
	<header
		class="header"
		:class="{
			'mode-extended': headerMode === 'extended',
			'mode-narrow': headerMode === 'narrow',
			'on-home-top': isHeaderOnHomeTop,
			'nav-visible': isNavListVisible && headerMode === 'narrow',
		}"
		id="header"
		ref="header"
	>
		<img
			class="header-logo-office"
			src="@/assets/logo-office-v2.png"
			alt="Logo de l'Office des Deux Clés"
			width="52"
			height="60"
		/>
		<LogoNotary class="header-logo-notary" />
		<HeaderNav
			ref="nav"
			:header-mode="headerMode"
			:is-nav-list-visible="isNavListVisible"
			@toggle-nav-list="toggleNavList"
			@hide-nav-list="hideNavList"
		/>
	</header>
</template>

<script>
import LogoNotary from "./LogoNotary.vue";
import HeaderNav from "./HeaderNav.vue";

export default {
	name: "AppHeader",

	components: {
		LogoNotary,
		HeaderNav,
	},

	data() {
		return {
			headerMode: "narrow",
			isHeaderOnHomeTop: true,
			isNavListVisible: false,
		};
	},

	mounted() {
		this.handleHeaderMode();
		window.addEventListener("resize", this.handleHeaderMode);

		this.setupHeader();

		this.saveHeaderHeight();
	},

	watch: {
		$route() {
			this.setupHeader();

			if (this.headerMode === "narrow") this.hideNavList();
		},

		headerMode() {
			if (this.headerMode === "extended") {
				this.showNavList();
			} else if (this.headerMode === "narrow") {
				this.hideNavList();
			}
		},
	},

	methods: {
		setupHeader() {
			if (this.$route.name === "home") {
				this.handleIsHeaderOnHomeTop();
				window.addEventListener("scroll", this.handleIsHeaderOnHomeTop);
			} else {
				this.isHeaderOnHomeTop = false;
				window.removeEventListener("scroll", this.handleIsHeaderOnHomeTop);
			}
		},

		handleHeaderMode() {
			this.headerMode = window.innerWidth > 1000 ? "extended" : "narrow";
		},

		handleIsHeaderOnHomeTop() {
			this.isHeaderOnHomeTop = window.scrollY < this.$refs.header.clientHeight;
		},

		saveHeaderHeight() {
			document.documentElement.style.setProperty(
				"--header-height",
				this.$refs.header.clientHeight + "px"
			);
		},

		toggleNavList() {
			this.isNavListVisible = !this.isNavListVisible;
		},

		hideNavList() {
			this.isNavListVisible = false;
		},

		showNavList() {
			this.isNavListVisible = true;
		},
	},

	unmounted() {
		window.removeEventListener("resize", this.handleHeaderMode);
		window.removeEventListener("scroll", this.handleIsHeaderOnHomeTop);
	},
};
</script>

<style>
.header {
	--offset-margin-sides: 0.5rem;

	width: 100%;
	padding-left: calc(var(--margin-sides) - var(--offset-margin-sides));
	padding-right: calc(var(--margin-sides) - var(--offset-margin-sides));
	position: fixed;
	top: 0;
	z-index: 10;

	display: grid;
	align-items: center;
	grid-template-columns: auto auto 1fr;
}

.header::before {
	content: "";
	background-color: var(--color-secondary-darker);
	opacity: 1;

	inset: 0;
	position: absolute;
	z-index: -1;
	transition: opacity 0.3s;
}

.header.on-home-top::before {
	opacity: 0;
}

.header.on-home-top.mode-narrow.nav-visible::before {
	opacity: 1;
}

.header-logo-office {
	margin: 1.5rem 0 1.5rem;
}

.header .logo-notary {
	height: 5.5rem;
	width: auto;

	margin-left: 1.5rem;
}

.header .logo-notary path {
	fill: var(--color-white-logo-notary);
}
</style>
