<template>
	<a class="nav-link-external" :href="link" target="_blank"
		><div class="nav-link-external-content">
			{{ text
			}}<iconify-icon
				class="nav-link-external-icon"
				icon="system-uicons:arrow-top-right"
			/>
		</div>
	</a>
</template>

<script>
export default {
	name: "NavLinkExternal",

	props: {
		link: String,
		text: String,
	},
};
</script>

<style>
.nav-link-external-content {
	width: fit-content;

	display: flex;
	align-items: center;
	gap: 0.125em;
}

.nav-link-external-icon {
	font-size: 1.25em;
}
</style>
