<template>
	<button
		class="btn"
		:class="{ active: isNavListVisible }"
		:aria-expanded="isNavListVisible"
		:aria-controls="navListId"
		aria-label="Navigation"
		title="Navigation"
		@click.stop="$emit('toggleNavList')"
	>
		<div class="rect"></div>
		<div class="rect"></div>
		<div class="rect"></div>
		<div class="rect"></div>
	</button>
</template>

<script>
export default {
	name: "NavListToggleBtn",

	props: {
		isNavListVisible: Boolean,
		navListId: String,
	},

	emits: ["toggleNavList"],
};
</script>

<style scoped>
.btn {
	border: unset;
	background-color: unset;
	font-size: 10px;
	width: 3em;
	height: 2.5em;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;

	position: relative;
}

.btn:focus-visible {
	outline-offset: 3px;
}

.btn .rect {
	width: 100%;
	height: 0.2em;
	background-color: white;

	position: absolute;
	transition: transform 0.3s;
}

/* Positions */

.btn :nth-child(1) {
	top: 0;
	transform-origin: right;
}

.btn :nth-child(2) {
	bottom: 0;
	transform-origin: left;
}

.btn :nth-child(3) {
	top: calc(50% - 0.1em);
}

.btn :nth-child(4) {
	top: calc(50% - 0.1em);
}

/* Transitions on event */

.btn.active :nth-child(1) {
	transform: scaleX(0);
}

.btn.active :nth-child(2) {
	transform: scaleX(0);
}

.btn.active :nth-child(3) {
	transform: rotate(45deg) scaleX(1.1);
}

.btn.active :nth-child(4) {
	transform: rotate(-45deg) scaleX(1.1);
}
</style>
